import React from 'react'
import styled from 'styled-components'
import colors from '../../lib/colors'
import Logo from './Logo'
import LinkedIn from './LinkedIn'
import Android from './Android'
import AppStore from './AppStore'

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${colors.bluedark};
  color: ${colors.white};
  padding: 75px 0px;
`

const SectionContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  @media (max-width: 1280px) {
    width: 90%;
    flex-direction: column;
  }
`

const LogoSection = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 350px;
  @media (max-width: 1280px) {
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
`

const LogoWrapper = styled.div`
  width: 153px;
`

const SocialIconWrapper = styled.div`
  width: 64px;
  margin: 3px;
`

const Links = styled.div`
  margin-top: 1em;
  a {
    color: ${colors.white};
  }
  &.services {
    margin-bottom: 1em;
  }
`
const Copyright = styled.div`
  @media (max-width: 1280px) {
    margin-top 1em;
  }
`

const Legal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  flex: 1 1 350px;
  font-size: 14px;
  @media (max-width: 1280px) {
    flex: 1;
  }
`

const Social = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1 1 350px;
  @media (max-width: 1280px) {
    justify-content: center;
    align-items: center;
    flex: 1;
  }
`

const Footer = () => (
  <Section id="footer">
    <SectionContent>
      <LogoSection>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
      </LogoSection>
      <Legal>
        <Copyright>
          Copyright © 2023 Torq Interface. All Rights Reserved.
        </Copyright>
        <Links>
          <a href="/privacy-policy">Privacy Policy</a>
          {' | '}
          <a href="https://docs.google.com/document/d/1up69P5H_rwlLiHkgEuNIYHXBHaaWd0yUPy82ufLZOAA/export?format=pdf">
            Security Overview
          </a>
        </Links>
        <Links className="services">
          <a href="https://hospital.torqinterface.com">Hospital</a>
          {' | '}
          <a href="https://kiosk.torqinterface.com">Kiosk</a>
          {' | '}
          <a href="https://warehouse.torqinterface.com">Warehouse</a>
        </Links>
      </Legal>
      <Social>
        <SocialIconWrapper>
          <a href="https://play.google.com/store/apps/developer?id=TORQ+Interface">
            <Android />
          </a>
        </SocialIconWrapper>
        <SocialIconWrapper>
          <a href="https://apps.apple.com/us/developer/torq-interface/id1469251064">
            <AppStore />
          </a>
        </SocialIconWrapper>
        <SocialIconWrapper>
          <a href="https://www.linkedin.com/company/torq-interface">
            <LinkedIn />
          </a>
        </SocialIconWrapper>
      </Social>
    </SectionContent>
  </Section>
)

export default Footer
